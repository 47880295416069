import React, {FC, useState} from 'react';
import {Alert, Button, Card, CardBody, Col, Container, Row} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {axiosApi} from '../../helpers/Axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import InstanceSelectorMultiple from '../../components/Instance/InstanceSelectorMultiple';
import TemplateSelector from "../../components/Message/TemplateSelector";
import TemplateOptions from "../../components/Message/TemplateOptions";

const WebinarRegistration: FC = () => {
    const breadcrumbItems: {
        title: string;
        link: string;
    }[] = [
        {title: 'Sharebee', link: '/'},
        {title: 'Wysyłka masowa', link: '/message/send'},
    ];

    const [selectedInstances, setSelectedInstances] = useState<string[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');
    const [options, setOptions] = useState<string[]>([]);
    const [result, setResult] = useState<null | { quantity: Number, emails: string[] }>(null);

    const send = () => {
        axiosApi
            .post(
                '/message/message/send',
                {
                    instances: selectedInstances,
                    template: 'message/message-templates/' + selectedTemplate,
                    options: options
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + sessionStorage.token,
                    },
                },
            )
            .then(result => {
                setResult(result.data);
                toastr.success('Zlecenie wysyłki zostało wygenerowane. Wkrótce rozpocznie sie wysyłka.')
            });
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Wysyłka masowa" breadcrumbItems={breadcrumbItems}/>
                <Card>
                    <CardBody>
                        <InstanceSelectorMultiple onChange={setSelectedInstances}/>
                        <TemplateSelector onChange={setSelectedTemplate}/>
                        <TemplateOptions onChange={setOptions}/>
                        <Row>
                            <Col lg="12">
                                <Button color="success" className="mt-2" onClick={send}>
                                    Generuj wysyłkę
                                </Button>
                            </Col>
                        </Row>
                        {result && <Row> <Col lg="12">
                            <Alert color={'success'} className="mt-2">
                                <p>
                                    Liczba odbirców: {result.quantity.toString()} <br />
                                    Odbiorcy: {result.emails.join(', ')}
                                </p>
                            </Alert>
                        </Col></Row>}
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};
export default WebinarRegistration;
