import React, {FC, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Input, Label, Row} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {useParams} from "react-router-dom";
import {axiosApi} from "../../helpers/Axios";
import toastr from 'toastr';
import TemplateOptions from "../../components/Message/TemplateOptions";

const allParameters = ['nazwa_klienta', 'rola', 'animacja', 'content', 'uuid'];

const Send: FC = () => {
    const {id} = useParams<string>();
    const [email, setEmail] = useState<string>('');
    const [template, setTemplate] = useState<MessageTemplate | undefined>(undefined);
    const [parameters, setParameters] = useState<string[]>([]);
    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
        axiosApi
            .get(`message/message-templates/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.token,
                },
            })
            .then(result => setTemplate(result.data));

    }, [id]);

    const send = (): void => {
        axiosApi
            .put(`message/message-templates/${id}/send`, {email, parameters, options}, {
                headers: {Authorization: 'Bearer ' + sessionStorage.token},
            })
            .then(() => toastr.success('Wysłano.'));
    };

    const breadcrumbItems: {
        title: string;
        link: string;
    }[] = [
        {title: 'Sharebee', link: '/'},
        {title: 'Szablony wiadomości', link: '/message/message-templates'},
        {title: (template?.name ?? '') + ' - Wysyłka testowa', link: '/message/message-templates'},
    ];

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Szablon wiadomości" breadcrumbItems={breadcrumbItems}/>
                <Card>
                    <CardBody>
                        <form>
                            <Row>
                                <Col lg="12">
                                    <Label className="form-label">E-mail</Label>
                                    <Input type="email" value={email}
                                           onChange={e => setEmail(e.target.value)}
                                           placeholder="E-mail"/>
                                </Col>

                                <Col lg="12">
                                    <h5 className={'mt-4'}>Parametry</h5>
                                    <Row className={'mt-2'}>
                                        {allParameters.map((variable, index) => (
                                            <Col lg="4" key={index}>
                                                <Label className="form-label mt-2">{variable}</Label>
                                                <Input type="text" value={parameters[variable] ?? ''}
                                                       onChange={e => setParameters({
                                                           ...parameters,
                                                           [variable]: e.target.value
                                                       })}
                                                       placeholder={variable}/>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                                <TemplateOptions onChange={setOptions} />
                                <Col lg="12">
                                    <Button color="success" className="mt-2" style={{float: "right"}} onClick={send}>
                                        Wyślij
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};
export default Send;
