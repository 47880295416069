import React, {FC, useEffect, useState} from 'react';
import {Col, Input, Label, Row} from 'reactstrap';

interface TemplateOptionsProps {
    onChange: (options: string[]) => void;
}

const allOptions: {name: string, options: string[]}[] = [
    {
        name: 'senderName',
        options: []
    },
    {
        name: 'senderEmail',
        options: []
    },
    {
        name: 'replyTo',
        options: []
    },
    {
        name: 'role',
        options: ['', 'User', 'Moderator', 'Admin']
    },
]

const TemplateOptions: FC<TemplateOptionsProps> = ({onChange}) => {
    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
        const renderedOptions = {
            ...options,
            'role': (options['role'] ?? '').toLowerCase().charAt(0)
        };

        onChange(renderedOptions);
    }, [options]);

    const formatName = (name: string): string => {
        return name.replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/^./, match => match.toUpperCase());
    }

    return (
        <>
            <Row>
                <Col lg="12">
                    <h5 className={'mt-4'}>Opcje</h5>
                    <Row className={'mt-2'}>
                        {allOptions.map((option, index) => (
                            <Col lg="4" key={index}>
                                <Label className="form-label mt-2">{formatName(option.name)}</Label>
                                {option.options.length < 1 &&
                                <Input type="text" value={options[option.name] ?? ''}
                                       onChange={e => setOptions({
                                           ...options,
                                           [option.name]: e.target.value
                                       })}
                                       placeholder={formatName(option.name)}/>}
                                {option.options.length > 0 &&
                                    <select
                                        id="custom-select"
                                        className="form-select"
                                        value={options[option.name] ?? ''}
                                        onChange={e => setOptions({
                                            ...options,
                                            [option.name]: e.target.value
                                        })}
                                    >
                                        {option.options.map(currentOption => (
                                            <option key={currentOption} value={currentOption|| ''}>
                                                {currentOption}
                                            </option>
                                        ))}
                                    </select>}
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </>
    );
};
export default TemplateOptions;
